import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
let ColorSchemeStore = class ColorSchemeStore extends Store {
    constructor() {
        super({});
    }
    setColorSchemes(schemes) {
        console.log('setColorSchemes ColorSchemeStore', schemes);
        this.update(cloneDeep(Object.assign({}, schemes)));
        localStorage.setItem(StoreNames.ColorScheme, JSON.stringify(schemes));
    }
    resetColorSchemes() {
        this.reset();
    }
};
ColorSchemeStore.ctorParameters = () => [];
ColorSchemeStore = __decorate([
    Injectable({ providedIn: 'root' }),
    StoreConfig({ name: StoreNames.ColorScheme }),
    __metadata("design:paramtypes", [])
], ColorSchemeStore);
export { ColorSchemeStore };
