import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomAppConfigService } from '@ho/shared/data-access/core/lib/services/custom-app-config.service';
let CountryCapacityRulesApiService = class CountryCapacityRulesApiService {
    constructor(_httpClient, _customAppConfigService) {
        var _a, _b;
        this._httpClient = _httpClient;
        this._customAppConfigService = _customAppConfigService;
        this.endpointUrl = '/feature/country-capacity-rules';
        this._capacityEndpointUrl = '/capacity';
        /* th:start */
        const extendedCapacity = ((_a = this._customAppConfigService.wlFeatures) === null || _a === void 0 ? void 0 : _a.includes("CUSTOM_HUGEOFFERS" /* CustomHugeoffers */)) || ((_b = this._customAppConfigService.wlFeatures) === null || _b === void 0 ? void 0 : _b.includes("COUNTRY_CAPACITY_RULE" /* CountryCapacityRule */));
        this._capacityEndpointUrl = extendedCapacity ? '/capacity' : '/offer-capacity';
        /* th:start */
    }
    list(body) {
        return this._httpClient.post(`${this._capacityEndpointUrl}/settings`, body, {
            observe: 'response',
        });
    }
    create(body) {
        return this._httpClient.post(`${this._capacityEndpointUrl}/create`, body);
    }
    getRule(id) {
        return this._httpClient.get(`${this.endpointUrl}/${id}`);
    }
    editRule(id, body) {
        return this._httpClient.put(`${this._capacityEndpointUrl}/${id}`, body);
    }
    deleteRule(id) {
        return this._httpClient.delete(`${this._capacityEndpointUrl}/${id}`);
    }
    setTop(id, isTop) {
        return this._httpClient.post(`${this._capacityEndpointUrl}/${id}/toggle-top`, { is_top: isTop });
    }
    simple(body) {
        return this._httpClient.post(`${this._capacityEndpointUrl}/simple`, body, {
            observe: 'response',
        });
    }
};
CountryCapacityRulesApiService.ctorParameters = () => [
    { type: HttpClient },
    { type: CustomAppConfigService }
];
CountryCapacityRulesApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient, CustomAppConfigService])
], CountryCapacityRulesApiService);
export { CountryCapacityRulesApiService };
