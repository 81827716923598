import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UiSettingsApiService } from '../services/ui-settings-api.service';
import { map, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { cloneDeep, isNil } from 'lodash-es';
import { DEFAULT_SCHEMES } from '../constants';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { UiSettingsQuery } from '../+state/ui-settings.query';
let UiSettingsService = class UiSettingsService {
    constructor(_uiSettingsApiService, _uiSettingsStore, _uiSettingsQuery) {
        this._uiSettingsApiService = _uiSettingsApiService;
        this._uiSettingsStore = _uiSettingsStore;
        this._uiSettingsQuery = _uiSettingsQuery;
    }
    getPreparedUiSettings() {
        const observable = localStorage.getItem('user_view_type')
            ? forkJoin([
                of({
                    grid_settings: null,
                    dashboard_settings: null,
                    modal_settings: null,
                    config_settings: null,
                    theme_settings: {
                        selected_color_scheme: 'Light',
                    },
                    tutorial_settings: { skip_tutorial: !!localStorage.getItem('skip_tutorial') },
                }),
                this.getThemes(),
            ]).pipe(map(([uiSettings, themes]) => {
                return this._mapUiSettings(uiSettings, themes);
            }))
            : forkJoin([this.getUiSettings(), this.getThemes()]).pipe(map(([uiSettings, themes]) => {
                return this._mapUiSettings(uiSettings, themes);
            }));
        return observable.pipe(tap((res) => {
            return this._uiSettingsStore.updateUiSettings(res);
        }), map((r) => r));
    }
    getThemes() {
        return new Observable((observer) => {
            this._uiSettingsApiService.getThemes().subscribe((themes) => {
                observer.next(themes);
                observer.complete();
            }, (error) => {
                observer.next(cloneDeep(DEFAULT_SCHEMES));
                observer.complete();
            });
        });
    }
    getUiSettings() {
        return this._uiSettingsApiService.getUiSettings();
    }
    updateColorSchemes(schemes) {
        return this._uiSettingsApiService.updateColorSchemes(schemes);
    }
    saveThemes(formData) {
        return this._uiSettingsApiService.saveThemes(formData);
    }
    updateConfigSettings(configSettings) {
        const observable = localStorage.getItem('user_view_type')
            ? of(configSettings)
            : this._uiSettingsApiService.updateConfigSettings(configSettings);
        return observable.pipe(tap((res) => this._uiSettingsStore.updateConfigSettings(configSettings)));
    }
    _mapUiSettings(uiSettings, themes) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const uiSettingsResponse = uiSettings;
        const defaultColorNames = cloneDeep(DEFAULT_SCHEMES).color_schemes.map((scheme) => scheme.name);
        const allColorNames = (_a = themes === null || themes === void 0 ? void 0 : themes.color_schemes) === null || _a === void 0 ? void 0 : _a.map((scheme) => scheme.name);
        const availableColorNames = (themes === null || themes === void 0 ? void 0 : themes.available_color_names) || [];
        let selectedColorScheme;
        if ((availableColorNames === null || availableColorNames === void 0 ? void 0 : availableColorNames.length) && (themes === null || themes === void 0 ? void 0 : themes.selected_color_scheme)) {
            if (availableColorNames.includes((_b = uiSettings.theme_settings) === null || _b === void 0 ? void 0 : _b.selected_color_scheme)) {
                selectedColorScheme = (_c = uiSettings.theme_settings) === null || _c === void 0 ? void 0 : _c.selected_color_scheme;
            }
            else {
                selectedColorScheme = themes === null || themes === void 0 ? void 0 : themes.selected_color_scheme;
            }
        }
        else {
            if (allColorNames === null || allColorNames === void 0 ? void 0 : allColorNames.includes((_d = uiSettings.theme_settings) === null || _d === void 0 ? void 0 : _d.selected_color_scheme)) {
                selectedColorScheme = (_e = uiSettings.theme_settings) === null || _e === void 0 ? void 0 : _e.selected_color_scheme;
            }
            else {
                selectedColorScheme = (themes === null || themes === void 0 ? void 0 : themes.selected_color_scheme) || 'Light';
            }
        }
        uiSettingsResponse.theme_settings = Object.assign(Object.assign(Object.assign({}, uiSettings.theme_settings), (themes ? themes : {})), { selected_color_scheme: selectedColorScheme || 'Light', use_auto_color_scheme: !isNil((_f = uiSettings.theme_settings) === null || _f === void 0 ? void 0 : _f.use_auto_color_scheme)
                ? (_g = uiSettings.theme_settings) === null || _g === void 0 ? void 0 : _g.use_auto_color_scheme : (themes === null || themes === void 0 ? void 0 : themes.use_auto_color_scheme) || false, color_schemes: [
                ...cloneDeep(DEFAULT_SCHEMES).color_schemes,
                ...(themes ? (_h = themes.color_schemes) === null || _h === void 0 ? void 0 : _h.filter((item) => !defaultColorNames.includes(item.name)) : []),
            ] });
        return uiSettingsResponse;
    }
};
UiSettingsService.ctorParameters = () => [
    { type: UiSettingsApiService },
    { type: UiSettingsStore },
    { type: UiSettingsQuery }
];
UiSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService,
        UiSettingsStore,
        UiSettingsQuery])
], UiSettingsService);
export { UiSettingsService };
