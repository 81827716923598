import { __decorate, __metadata } from "tslib";
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { pluck, switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { permissionsList } from '@ho/shared/widgets/permission-settings';
import { DEFAULT_SCHEMES, UiSettingsService } from '@ho/shared/data-access/ui-settings';
import { SettingsService } from '../../../../core/src/lib/+state/settings.service';
import { ThemeSettingsService } from '../../../../ui-settings/src/lib/services/theme-settings.service';
import { SessionApiService } from './session-api.service';
import { UserWorkspaceMap, UserWorkspaceRole } from './session-user-type-service.service';
import { SessionQuery } from './session.query';
import { SessionStore } from './session.store';
import { cloneDeep } from 'lodash-es';
let SessionService = class SessionService {
    constructor(_ngxPermissionService, _authStore, _authQuery, _authDataService, _http, _jwtHelper, _uiSettingsService, _system, _translate, _themeSettingsService, _environment, _document) {
        this._ngxPermissionService = _ngxPermissionService;
        this._authStore = _authStore;
        this._authQuery = _authQuery;
        this._authDataService = _authDataService;
        this._http = _http;
        this._jwtHelper = _jwtHelper;
        this._uiSettingsService = _uiSettingsService;
        this._system = _system;
        this._translate = _translate;
        this._themeSettingsService = _themeSettingsService;
        this._environment = _environment;
        this._document = _document;
    }
    autologin(token) {
        let isTokenExpired;
        if (token) {
            try {
                isTokenExpired = this._jwtHelper.isTokenExpired(token);
            }
            catch (error) {
                isTokenExpired = true;
            }
        }
        if (!token) {
            Sentry.setTag('USER_ROLE', UserWorkspaceMap.get(this._environment.role[0]));
            Sentry.setTag('CLIENT_ID', this._environment.sentryClientTag);
            Sentry.captureMessage('Token is absent', Severity.Error);
            this._authStore.logout();
        }
        if (isTokenExpired) {
            Sentry.setTag('USER_ROLE', UserWorkspaceMap.get(this._environment.role[0]));
            Sentry.setTag('CLIENT_ID', this._environment.sentryClientTag);
            Sentry.captureMessage('Token expired', Severity.Error);
            this._authStore.logout();
        }
        return of(token).pipe(tap(() => {
            this._authStore.login(token);
        }), switchMap((_) => this._gettingUserInfo()));
    }
    login(credentials) {
        let tfaEnabled;
        return this._authDataService.login(credentials).pipe(pluck('data'), tap(() => {
            localStorage.removeItem('old_route');
            localStorage.removeItem('old_token');
        }), tap((response) => {
            this._authStore.login(response.jwt);
            tfaEnabled = response.two_fa_required;
        }), switchMap((_) => (tfaEnabled ? of({ data: { tfa_enabled: tfaEnabled } }) : this._gettingUserInfo())));
    }
    updateUserInfo(userInfo) {
        this._authStore.update((_) => {
            return Object.assign({ token: localStorage.getItem(this._environment.jwtTokenName) }, { userInfo });
        });
    }
    clearUserInfo() {
        this._authStore.update((_) => {
            return Object.assign({ token: localStorage.getItem(this._environment.jwtTokenName) }, { userInfo: null });
        });
    }
    logout() {
        this._authStore.logout();
    }
    save(body, options) {
        return this._http.put(`/me`, body, options);
    }
    processingUserPermissions(permissions) {
        permissionsList.forEach((permissionBlock) => {
            permissionBlock.permissions.forEach((permission) => {
                this._ngxPermissionService.addPermission(permission.key, () => {
                    return permissions.includes(permission.key);
                });
            });
        });
        this._enableCustomPermissions();
    }
    switchApp(observer) {
        switch (this._authQuery.userInfo().workspace_role_id) {
            case UserWorkspaceRole.Aff:
                this._document.location.href = `${location.origin}/v3/affiliate/d/dashboard`;
                break;
            case UserWorkspaceRole.AM:
                this._document.location.href = `${location.origin}/v3/affiliate-manager/d/dashboard`;
                break;
            case UserWorkspaceRole.Admin:
                this._document.location.href = `${location.origin}/v3/admin/d/dashboard`;
                break;
            case UserWorkspaceRole.AD:
                this._document.location.href = `${location.origin}/v3/customer/d/dashboard`;
                break;
            default:
                this._document.location.href = `${location.origin}/v3/affiliate-team-lead/d/dashboard`;
                break;
        }
        if (observer) {
            observer.next(false);
            observer.complete();
        }
    }
    _gettingUserInfo() {
        return of({}).pipe(switchMap((_) => this._authDataService.currentUserInfo()), pluck('data'), tap((response) => {
            this._authStore.update((state) => {
                return Object.assign(Object.assign({}, state), { userInfo: response });
            });
            const lang = response.language || 'en';
            localStorage.setItem('language', lang);
            this._translate.use(lang);
        }), switchMap((r) => this._uiSettingsService.getPreparedUiSettings()), switchMap((r) => {
            var _a, _b;
            console.log('r ->', r);
            const defaultThemeNames = cloneDeep(DEFAULT_SCHEMES).color_schemes.map((scheme) => scheme.name);
            const colorSchemes = Object.assign(Object.assign(Object.assign({}, cloneDeep(DEFAULT_SCHEMES)), r.theme_settings), { color_schemes: [
                    ...cloneDeep(DEFAULT_SCHEMES).color_schemes,
                    ...(((_b = (_a = r === null || r === void 0 ? void 0 : r.theme_settings) === null || _a === void 0 ? void 0 : _a.color_schemes) === null || _b === void 0 ? void 0 : _b.filter((scheme) => {
                        return !defaultThemeNames.includes(scheme.name);
                    })) || []),
                ] });
            this._themeSettingsService.initThemeSettings(colorSchemes);
            // because auth-app is not separate application in dev mode, permission initializer in other case
            return !this._environment.production
                ? this._authDataService.myPermissions()
                : of({
                    data: null,
                });
        }), pluck('data'), tap((myPermissions) => {
            if (!myPermissions) {
                return;
            }
            this.processingUserPermissions(myPermissions);
            this._authStore.update((state) => {
                return Object.assign(Object.assign({}, state), { userInfo: Object.assign(Object.assign({}, this._authQuery.userInfo()), { permissions: myPermissions }) });
            });
        }));
    }
    _enableCustomPermissions() {
        Promise.all([
            this._ngxPermissionService.hasPermission('UI_AdvertiserBilling_View'),
            this._ngxPermissionService.hasPermission('UI_UserOperations_View'),
            this._ngxPermissionService.hasPermission('UI_UserOperations_CreateUser'),
            this._ngxPermissionService.hasPermission('UI_ClickPayoutRules_View'),
            this._ngxPermissionService.hasPermission('UI_ClickBilling_View'),
            this._ngxPermissionService.hasPermission('UI_PushBrokers_View'),
            this._ngxPermissionService.hasPermission('UI_CRG_View'),
            this._ngxPermissionService.hasPermission('UI_EPL_View'),
        ]).then(([UI_AdvertiserBilling_View, UI_UserOperations_View, UI_UserOperations_CreateUser, UI_ClickPayoutRules_View, UI_ClickBilling_View, UI_PushBrokers_View, UI_CRG_View, UI_EPL_View,]) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            if ((_a = this._system.settings) === null || _a === void 0 ? void 0 : _a.workspace_features.includes('cpa_network_solution')) {
                this._ngxPermissionService.addPermission('UI_Enable_Advertiser');
                if (UI_AdvertiserBilling_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_DISPLAY_ADVERTISER_BILLING');
                }
                if (UI_UserOperations_CreateUser) {
                    this._ngxPermissionService.addPermission('CUSTOM_CREATE_ADVERTISER');
                }
                if (UI_UserOperations_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_ARCHIVES_ADVERTISER');
                }
            }
            if ((_b = this._system.settings) === null || _b === void 0 ? void 0 : _b.workspace_features.includes('click_commissions')) {
                this._ngxPermissionService.addPermission('CUSTOM_CLICK_COMMISSIONS', () => true);
                if (UI_ClickPayoutRules_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_UI_ClickPayoutRules_View', () => true);
                }
                if (UI_ClickBilling_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_UI_ClickBilling_View', () => true);
                }
            }
            if ((_c = this._system.settings) === null || _c === void 0 ? void 0 : _c.workspace_features.includes('automatically_create_revenue_rule')) {
                this._ngxPermissionService.addPermission('CUSTOM_AUTOMATICALLY_CREATE_REVENUE_RULE', () => true);
            }
            if ((_d = this._system.settings) === null || _d === void 0 ? void 0 : _d.workspace_features.includes('push_brokers')) {
                if (UI_PushBrokers_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_UI_PushBrokers_View', () => true);
                }
            }
            if ((_e = this._system.settings) === null || _e === void 0 ? void 0 : _e.workspace_features.includes('crg')) {
                if (UI_CRG_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_UI_CRG_View', () => true);
                }
            }
            if ((_f = this._system.settings) === null || _f === void 0 ? void 0 : _f.workspace_features.includes('epl')) {
                if (UI_EPL_View) {
                    this._ngxPermissionService.addPermission('CUSTOM_UI_EPL_View', () => true);
                }
            }
            if ((_g = this._system.settings) === null || _g === void 0 ? void 0 : _g.workspace_features.includes('hide_revenue')) {
                this._ngxPermissionService.addPermission('CUSTOM_UI_Hide_Revenue_View', () => true);
            }
            if (((_h = this._system.settings) === null || _h === void 0 ? void 0 : _h.workspace_features.includes('theming_customization')) &&
                !localStorage.getItem('user_view_type')) {
                this._ngxPermissionService.addPermission('CUSTOM_UI_Theming_Customization', () => true);
            }
        });
    }
};
SessionService.ctorParameters = () => [
    { type: NgxPermissionsService },
    { type: SessionStore },
    { type: SessionQuery },
    { type: SessionApiService },
    { type: HttpClient },
    { type: JwtHelperService },
    { type: UiSettingsService },
    { type: SettingsService },
    { type: TranslateService },
    { type: ThemeSettingsService },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Document, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];
SessionService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgxPermissionsService,
        SessionStore,
        SessionQuery,
        SessionApiService,
        HttpClient,
        JwtHelperService,
        UiSettingsService,
        SettingsService,
        TranslateService,
        ThemeSettingsService, Object, Document])
], SessionService);
export { SessionService };
