// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { WlCurrency } from '../../../../tools/environments'; // Included with Angular CLI.
import { version } from '../../../../package.json';
const sentryConfig = {
    dsn: 'https://d947e5eb231040b5b1ef1e5f466c4943@sentry.leaddist.team/9',
    debug: false,
    release: version,
    environment: process.env.CLIENT_NAME + '-dev',
};
export const environment = {
    production: false,
    sentryClientTag: process.env.CLIENT_NAME + '-dev',
    apiUrl: 'https://hulk-api.t4u-ho.team/rest',
    appUrl: 'https://hulk-panel.t4u-ho.team/v3',
    jwtTokenName: 'id_token',
    deployUrl: '/',
    role: [],
    gtmUID: 'GTM-W62H33G',
    gaUID: 'UA-126644119-2',
    projectVersion: version,
    sentryConfig,
    wlId: '',
    wlAuthLogo: './assets/images/logo.svg',
    wlMainLargeLogo: './assets/images/logos/main-logo.svg',
    wlMainSmallLogo: './assets/images/logos/small-logo.svg',
    wlFavicon: '',
    wlPreloader: './assets/images/loading/loading-logo.png',
    wlCompanyName: 'Tracking Hub',
    wlHomePageLink: 'https://hugeoffers.com',
    wlTNCLink: 'https://hugeoffers.com/terms-and-conditions',
    wlSupportEmail: 'info@hugeoffers.com',
    wlSupportSkype: 'technical.support.HugeOffers',
    wlRegistrationSupportSkype: 'live:.cid.de122d97721bdde7',
    wlRegistrationSupportTelegram: 'KateHelpHO',
    wlCurrency: WlCurrency.USD,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';
