import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { FileHelper } from '@ho/shared/helpers/file.helper';
import { UiSettingsService } from './ui-settings.service';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import { DEFAULT_SCHEMES, TypeTheme } from '../constants';
import { ColorSchemeStore } from '../../../../../../theming/src/lib/state/color-scheme.store';
import { cloneDeep } from 'lodash-es';
let ThemeSettingsService = class ThemeSettingsService {
    constructor(_uiSettingsService, _uiSettingsStore, _colorSchemeStore) {
        this._uiSettingsService = _uiSettingsService;
        this._uiSettingsStore = _uiSettingsStore;
        this._colorSchemeStore = _colorSchemeStore;
        this.isDarkMode = false;
        this._use_auto_color_scheme = false;
        this.root = document === null || document === void 0 ? void 0 : document.documentElement;
    }
    setProperty(variable, value) {
        this.root.style.setProperty(variable, value);
    }
    updateColorSchemes(schemes) {
        return this._uiSettingsService.updateColorSchemes(schemes);
    }
    patchProperties(properties) {
        this.root.setAttribute('style', properties);
    }
    initColorSchemeWatcher() {
        if (this._isColorSchemeWatcherTurnOn) {
            return;
        }
        this._isColorSchemeWatcherTurnOn = true;
        fromEvent(window, 'storage').subscribe((evt) => {
            if (evt.key !== StoreNames.ColorScheme) {
                return;
            }
            this.applyThemes(JSON.parse(evt.newValue));
        });
    }
    applyThemes(colorSchemes) {
        var _a, _b, _c, _d, _e;
        console.log('applyThemes colorSchemes', colorSchemes);
        let colorScheme = (_b = (_a = colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes.color_schemes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name ===
            (colorSchemes.use_auto_color_scheme
                ? this.isDarkMode
                    ? colorSchemes.dark_color_name || TypeTheme.Dark
                    : colorSchemes.light_color_name || TypeTheme.Light
                : colorSchemes.selected_color_scheme))) === null || _b === void 0 ? void 0 : _b.color_scheme;
        console.log('found colorScheme ->', colorScheme);
        if (!colorScheme) {
            colorScheme =
                ((_d = (_c = colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes.color_schemes) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === TypeTheme.Light)) === null || _d === void 0 ? void 0 : _d.color_scheme) || ((_e = cloneDeep(DEFAULT_SCHEMES).color_schemes.find((item) => item.name === TypeTheme.Light)) === null || _e === void 0 ? void 0 : _e.color_scheme);
        }
        this.setColorSchemes((colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes.color_schemes) ? colorSchemes : cloneDeep(DEFAULT_SCHEMES));
        this.patchProperties(Object.keys(colorScheme).reduce((accScheme, key) => {
            accScheme += colorScheme[key].reduce((acc, item) => {
                acc += `${item.variable}:${item.value};`;
                return acc;
            }, '');
            return accScheme;
        }, ''));
        localStorage.setItem('selected_color_scheme', colorSchemes.selected_color_scheme);
    }
    // updateThemeConfig(schemes: IColorSchemes): Observable<object> {
    //   return (localStorage.getItem('user_view_type')
    //     ? of({})
    //     : this._uiSettingsService.updateColorSchemes(schemes)
    //   ).pipe(
    //     tap(() => {
    //       this._uiSettingsStore.updateThemeSettings({
    //         selected_color_scheme: schemes.selected_color_scheme,
    //         use_auto_color_scheme: schemes.use_auto_color_scheme || false,
    //       });
    //     })
    //   );
    // }
    updateThemeSettingsLocal(themeSettings) {
        this._uiSettingsStore.updateThemeSettings(themeSettings);
    }
    changeUseAutoColorScheme(event) {
        this._use_auto_color_scheme = event;
    }
    initThemeSettings(colorSchemes) {
        var _a;
        console.log('initThemeSettings - colorSchemes', colorSchemes);
        this._use_auto_color_scheme = colorSchemes.use_auto_color_scheme;
        const applyThemes = (selected_color_scheme) => {
            const themes = Object.assign(Object.assign({}, colorSchemes), { selected_color_scheme, use_auto_color_scheme: this._use_auto_color_scheme });
            console.log('initThemeSettings', themes);
            this.applyThemes(themes);
        };
        const colorSchemeNames = (_a = colorSchemes.color_schemes) === null || _a === void 0 ? void 0 : _a.map((scheme) => scheme.name);
        let selectedColorScheme = (colorSchemeNames === null || colorSchemeNames === void 0 ? void 0 : colorSchemeNames.includes(colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes.selected_color_scheme)) ? colorSchemes === null || colorSchemes === void 0 ? void 0 : colorSchemes.selected_color_scheme : null;
        if (!selectedColorScheme) {
            selectedColorScheme = TypeTheme.Light;
            localStorage.setItem('selected_color_scheme', selectedColorScheme);
        }
        console.log('before selectedColorScheme', selectedColorScheme);
        this.isDarkMode = false;
        if (window.matchMedia) {
            this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (this._use_auto_color_scheme) {
                selectedColorScheme = this.isDarkMode
                    ? colorSchemes.dark_color_name || TypeTheme.Dark
                    : colorSchemes.light_color_name || TypeTheme.Light;
            }
            console.log('after selectedColorScheme', selectedColorScheme);
            applyThemes(selectedColorScheme);
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
                if (this._use_auto_color_scheme) {
                    this.isDarkMode = event.matches;
                    selectedColorScheme = this.isDarkMode
                        ? colorSchemes.dark_color_name || TypeTheme.Dark
                        : colorSchemes.light_color_name || TypeTheme.Light;
                    console.log('after change system selectedColorScheme', selectedColorScheme);
                    applyThemes(selectedColorScheme);
                }
            });
        }
        else {
            applyThemes(selectedColorScheme);
        }
        return true;
    }
    saveThemes(themes) {
        var _a;
        if (!((_a = themes.available_color_names) === null || _a === void 0 ? void 0 : _a.length)) {
            themes['available_color_names'] = null;
        }
        const blob = FileHelper.objectToJSONBlob(themes);
        const file = new File([blob], 'color-schemes.json', { type: 'application/json' });
        const formData = new FormData();
        formData.append('file', file);
        return this._uiSettingsService.saveThemes(formData);
    }
    getThemes() {
        return this._uiSettingsService.getThemes();
    }
    setColorSchemes(schemes) {
        this._colorSchemeStore.setColorSchemes(schemes);
    }
};
ThemeSettingsService.ctorParameters = () => [
    { type: UiSettingsService },
    { type: UiSettingsStore },
    { type: ColorSchemeStore }
];
ThemeSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsService,
        UiSettingsStore,
        ColorSchemeStore])
], ThemeSettingsService);
export { ThemeSettingsService };
