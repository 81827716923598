import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// On API used boolean type, only for select converting to enum. Use only for select
export var TestStatus;
(function (TestStatus) {
    TestStatus[TestStatus["True"] = 1] = "True";
    TestStatus[TestStatus["False"] = 0] = "False";
})(TestStatus || (TestStatus = {}));
// Use only for select
export const TestStatusMap = new Map([
    [TestStatus.True, 'Report.Text.True'],
    [TestStatus.False, 'Report.Text.False'],
]);
let TestStatusService = class TestStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TestStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(+status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        return status ? 'text-danger' : '';
    }
};
TestStatusService.ctorParameters = () => [
    { type: TranslateService }
];
TestStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TestStatusService);
export { TestStatusService };
