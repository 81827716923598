import { DARK_COLOR_SCHEME } from './DARK_COLORS';
import { STANDARD_COLOR_SCHEME } from './LIGHT_COLORS';
import { RED_COLOR_SCHEME } from './RED_COLORS';
import { DARK_RED_COLOR_SCHEME } from './DARK_RED_COLORS';
import { DARK_GREY_COLOR_SCHEME } from './DARK_GREY_COLORS';
import { BROWN_COLOR_SCHEME } from './BROWN_COLORS';
export var TypeTheme;
(function (TypeTheme) {
    TypeTheme["Light"] = "Light";
    TypeTheme["Dark"] = "Dark";
    TypeTheme["Red"] = "Red";
    TypeTheme["DarkRed"] = "Dark Red";
    TypeTheme["DarkGrey"] = "Dark Grey";
    TypeTheme["Brown"] = "Brown";
    TypeTheme["Green"] = "Green";
})(TypeTheme || (TypeTheme = {}));
export const TYPE_THEMES_MAP = new Map([
    [TypeTheme.Light, 'Light'],
    [TypeTheme.Dark, 'Dark'],
    [TypeTheme.Red, 'Red'],
    [TypeTheme.DarkRed, 'Dark Red'],
    [TypeTheme.DarkGrey, 'Dark Grey'],
    [TypeTheme.Brown, 'Brown'],
]);
export const DEFAULT_SCHEMES = {
    selected_color_scheme: TypeTheme.Light,
    use_auto_color_scheme: false,
    color_schemes: [
        {
            name: TypeTheme.Light,
            color_scheme: STANDARD_COLOR_SCHEME,
            default_color_scheme: STANDARD_COLOR_SCHEME,
            required: true,
        },
        {
            name: TypeTheme.Dark,
            color_scheme: DARK_COLOR_SCHEME,
            default_color_scheme: DARK_COLOR_SCHEME,
            required: true,
        },
        {
            name: TypeTheme.Red,
            color_scheme: RED_COLOR_SCHEME,
            default_color_scheme: RED_COLOR_SCHEME,
            required: true,
        },
        {
            name: TypeTheme.DarkRed,
            color_scheme: DARK_RED_COLOR_SCHEME,
            default_color_scheme: DARK_RED_COLOR_SCHEME,
            required: true,
        },
        {
            name: TypeTheme.DarkGrey,
            color_scheme: DARK_GREY_COLOR_SCHEME,
            default_color_scheme: DARK_GREY_COLOR_SCHEME,
            required: true,
        },
        {
            name: TypeTheme.Brown,
            color_scheme: BROWN_COLOR_SCHEME,
            default_color_scheme: BROWN_COLOR_SCHEME,
            required: true,
        },
    ],
};
