import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PaymentStatuses;
(function (PaymentStatuses) {
    PaymentStatuses[PaymentStatuses["Pending"] = 0] = "Pending";
    PaymentStatuses[PaymentStatuses["AwaitingInvoice"] = 1] = "AwaitingInvoice";
    PaymentStatuses[PaymentStatuses["Completed"] = 2] = "Completed";
    PaymentStatuses[PaymentStatuses["Bounced"] = 3] = "Bounced";
    PaymentStatuses[PaymentStatuses["Cancelled"] = 4] = "Cancelled";
    PaymentStatuses[PaymentStatuses["InvoiceReceived"] = 5] = "InvoiceReceived";
    PaymentStatuses[PaymentStatuses["InvoiceConfirmed"] = 7] = "InvoiceConfirmed";
    PaymentStatuses[PaymentStatuses["PaymentSent"] = 8] = "PaymentSent";
    PaymentStatuses[PaymentStatuses["Moderated"] = 9] = "Moderated";
})(PaymentStatuses || (PaymentStatuses = {}));
export const PaymentStatusesMap = new Map([
    [PaymentStatuses.Pending, 'Global.Status.Pending'],
    [PaymentStatuses.InvoiceConfirmed, 'Global.Options.PaymentStatuses.InvoiceConfirmed'],
    [PaymentStatuses.InvoiceReceived, 'Global.Options.PaymentStatuses.InvoiceReceived'],
    [PaymentStatuses.PaymentSent, 'Global.Options.PaymentStatuses.PaymentSent'],
    [PaymentStatuses.Completed, 'Global.Options.PaymentStatuses.Completed'],
    [PaymentStatuses.Cancelled, 'Global.Options.PaymentStatuses.Cancelled'],
    [PaymentStatuses.Bounced, 'Global.Options.PaymentStatuses.Bounced'],
    [PaymentStatuses.AwaitingInvoice, 'Global.Options.PaymentStatuses.AwaitingInvoice'],
]);
let PaymentStatusService = class PaymentStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PaymentStatusesMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStatusClass(status) {
        switch (+status) {
            case PaymentStatuses.InvoiceReceived:
            case PaymentStatuses.InvoiceConfirmed:
            case PaymentStatuses.PaymentSent:
            case PaymentStatuses.Completed:
                return 'text-success';
            case PaymentStatuses.Cancelled:
            case PaymentStatuses.Bounced:
                return 'text-danger';
            case PaymentStatuses.Pending:
            case PaymentStatuses.AwaitingInvoice:
            case PaymentStatuses.Moderated:
                return 'text-warning';
            default:
                return '';
        }
    }
};
PaymentStatusService.ctorParameters = () => [
    { type: TranslateService }
];
PaymentStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PaymentStatusService);
export { PaymentStatusService };
