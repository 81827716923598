import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map, debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { RoutingHelper } from '@ho/shared/helpers/routing.helper';
import { isArray, isEqual, isNil, omitBy } from 'lodash-es';
let DataGridHelper = class DataGridHelper {
};
DataGridHelper = __decorate([
    Injectable()
], DataGridHelper);
export { DataGridHelper };
export function updateFilterForm(params) {
    this.filterForm.reset(params, { emitEvent: false });
    if (this.filterForm.valid) {
        this._filterFormInitialValue = Object.assign({}, this.filterForm.value);
    }
    else {
        this.filterForm.reset(this._filterFormInitialValue, { emitEvent: false });
    }
}
export function initFormChangesSubscription() {
    let cachedValue = this.filterForm.value;
    this.filterForm.valueChanges
        .pipe(map((result) => {
        if (typeof result['page'] === 'undefined') {
            return result;
        }
        if (typeof cachedValue['_ts'] === 'undefined') {
            console.error('You should add _ts parameter into form');
        }
        if (+result['page'] === +cachedValue['page'] && +result['_ts'] === +cachedValue['_ts']) {
            result['page'] = 1;
        }
        cachedValue = result;
        return result;
    }), distinctUntilChanged(), debounceTime(400), map((r) => this._mapFiltersToParams
        ? this._mapFiltersToParams(RoutingHelper.preparePayload(r))
        : _mapFiltersToParams(RoutingHelper.preparePayload(r))), untilDestroyed(this))
        .subscribe((params) => {
        if (params['group_by'] && params['_ts']) {
            params['_ts'] = +new Date();
        }
        if (this._cdr) {
            this._cdr.markForCheck();
        }
        this._router.navigate([], {
            queryParams: params,
            relativeTo: this._activatedRoute,
        });
        setFilters.call(this, params);
        updateFilterForm.bind(this)(params);
    });
}
export function initFormChangesSubscriptionWORouting() {
    let cachedValue = this.filterForm.value;
    this.filterForm.valueChanges
        .pipe(startWith(this.filterForm.value), map((result) => {
        if (typeof result['page'] === 'undefined') {
            return result;
        }
        if (typeof cachedValue['_ts'] === 'undefined') {
            console.error('You should add _ts parameter into form');
        }
        if (+result['page'] === +cachedValue['page'] && +result['_ts'] === +cachedValue['_ts']) {
            result['page'] = 1;
        }
        cachedValue = result;
        return result;
    }), distinctUntilChanged(), debounceTime(400), map(this._mapFiltersToParams ? this._mapFiltersToParams.bind(this) : _mapFiltersToParams.bind(this)), untilDestroyed(this))
        .subscribe((params) => {
        if (this._cdr) {
            this._cdr.markForCheck();
        }
        this.gridConfig.filters$.subscribe(() => {
            initFilters.call(this, params);
        });
        if (this.gridConfig.filters$.isStopped) {
            setFilters.call(this, params);
            initFilters.call(this, params);
        }
    });
}
export function initRouterChangesSubscription() {
    this._activatedRoute.queryParams
        .pipe(distinctUntilChanged(), map(this._mapParamsToFilters ? this._mapParamsToFilters.bind(this) : _mapParamsToFilters.bind(this)), untilDestroyed(this))
        .subscribe((filters) => {
        updateFilterForm.bind(this)(filters);
        this.gridConfig.filters$.subscribe(() => {
            initFilters.call(this, filters);
        });
        if (this.gridConfig.filters$.isStopped) {
            initFilters.call(this, filters);
        }
    });
}
export function onSortChangeFn(sort) {
    let result = null;
    switch (sort.direction) {
        case 'asc':
            result = sort.active;
            break;
        case 'desc':
            result = `-${sort.active}`;
            break;
    }
    if (this.filterForm.get('sort_by')) {
        this.filterForm.get('sort_by').setValue(result ? [result] : []);
    }
    else {
        this.filterForm.get('sort').setValue(result);
    }
}
export function onMobileResizableFn() {
    this.isMobileResizable = !this.isMobileResizable;
    if (this._cdr) {
        this._cdr.detectChanges();
    }
}
export function getFakeAutocompleteRestResponse(value, params) {
    return {
        success: true,
        status: 200,
        data: [],
    };
}
function setFilters(params) {
    if (this.gridConfig && this.gridConfig.filters.length) {
        const prevFilters = [];
        const currFilters = [];
        this.gridConfig.filters.forEach((item) => {
            if (params.hasOwnProperty(item.key)) {
                prevFilters.push(item);
                currFilters.push({
                    key: item.key,
                    value: +params[item.key],
                });
            }
        });
        if (!isEqual(prevFilters, currFilters)) {
            this.gridConfig.setFilters(currFilters);
        }
    }
}
function initFilters(filters) {
    const filtersFromApi = this.gridConfig.getFilters();
    const matchedFilters = Object.keys(filtersFromApi).reduce((acc, item) => {
        if (filters.hasOwnProperty(item)) {
            acc[item] = filtersFromApi[item];
        }
        return acc;
    }, {});
    const mergedFilters = Object.assign(filters, matchedFilters);
    mergedFilters['excluded_fields'] = mergedFilters['excluded_fields']
        ? isArray(mergedFilters['excluded_fields'])
            ? mergedFilters['excluded_fields']
            : [mergedFilters['excluded_fields']]
        : [];
    updateFilterForm.call(this, mergedFilters);
    this.dataSource.loadData(RoutingHelper.preparePayload(mergedFilters));
}
export function getSort(sortBy) {
    const sort = {
        sortActive: '',
        sortDirection: '',
    };
    const matchBy = /^-/;
    // ToDo: this fix for duplicate call after refresh filters in report/th/admin-shell/traffic-list.container.ts
    if (!sortBy || typeof sortBy !== 'string') {
        return sort;
    }
    sort.sortActive = sortBy.replace(matchBy, '');
    sort.sortDirection = matchBy.test(sortBy) ? 'desc' : 'asc';
    return sort;
}
function _mapFiltersToParams(filters) {
    return omitBy(filters, isNil);
}
function _mapParamsToFilters(queryParams) {
    return omitBy(queryParams, isNil);
}
