import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// On API used boolean type, only for select converting to enum. Use only for select
export var AutologinStatus;
(function (AutologinStatus) {
    AutologinStatus[AutologinStatus["Used"] = 1] = "Used";
    AutologinStatus[AutologinStatus["NotUsed"] = 0] = "NotUsed";
})(AutologinStatus || (AutologinStatus = {}));
// Use only for select
export const AutologinStatusMap = new Map([
    [AutologinStatus.Used, 'Global.AutologinStatus.Used'],
    [AutologinStatus.NotUsed, 'Global.AutologinStatus.NotUsed'],
]);
let AutologinStatusService = class AutologinStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = AutologinStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(+status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        return status ? 'text-success' : 'text-danger';
    }
};
AutologinStatusService.ctorParameters = () => [
    { type: TranslateService }
];
AutologinStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], AutologinStatusService);
export { AutologinStatusService };
