import { StoreNames } from '@ho/shared/constants/store-config.enum';
import { DEFAULT_SCHEMES, STANDARD_ALL_COLORS, TypeTheme, } from '@ho/shared/data-access/ui-settings';
import { cloneDeep } from 'lodash-es';
export class ColorSchemeFactory {
    constructor(document) {
        this.document = document;
        this.root = document === null || document === void 0 ? void 0 : document.documentElement;
    }
    patchProperties(properties) {
        this.root.setAttribute('style', properties);
    }
    patchPropertiesFromWlConfig(scheme) {
        var _a, _b, _c, _d, _e;
        const localColorSchemesJson = localStorage.getItem(StoreNames.ColorScheme);
        const localColorSchemes = localColorSchemesJson ? JSON.parse(localColorSchemesJson) : null;
        if (scheme && !localColorSchemes) {
            this.patchProperties(Object.keys(scheme).reduce((accScheme, key) => {
                accScheme += scheme[key].reduce((acc, item) => {
                    acc += `${item.variable}:${item.value};`;
                    return acc;
                }, '');
                return accScheme;
            }, ''));
        }
        else if (localColorSchemes) {
            let localScheme = (_b = (_a = localColorSchemes === null || localColorSchemes === void 0 ? void 0 : localColorSchemes.color_schemes) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === localColorSchemes.selected_color_scheme)) === null || _b === void 0 ? void 0 : _b.color_scheme;
            if (!localScheme) {
                localScheme =
                    ((_d = (_c = localColorSchemes === null || localColorSchemes === void 0 ? void 0 : localColorSchemes.color_schemes) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === TypeTheme.Light)) === null || _d === void 0 ? void 0 : _d.color_scheme) || ((_e = cloneDeep(DEFAULT_SCHEMES).color_schemes.find((item) => item.name === TypeTheme.Light)) === null || _e === void 0 ? void 0 : _e.color_scheme);
            }
            this.patchProperties(Object.keys(localScheme).reduce((accScheme, key) => {
                accScheme += localScheme[key].reduce((acc, item) => {
                    acc += `${item.variable}:${item.value};`;
                    return acc;
                }, '');
                return accScheme;
            }, ''));
        }
        else {
            this.patchProperties(STANDARD_ALL_COLORS.reduce((acc, item) => {
                acc += `${item.variable}:${item.value};`;
                return acc;
            }, ''));
        }
    }
}
