import { RoutingHelper } from './routing.helper';
import { get, isNil, omitBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { PickerDefaultPeriod } from '@ho/shared/ui/form/components/range-select/range-select.model';
export class MapParamsToFilters {
    constructor(queryParams, filterFormInitialValue = {}, _filterForm) {
        this._queryParams = {};
        this._filterFormInitialValue = {};
        this._queryParams = omitBy(Object.assign(Object.assign({}, queryParams), (this._queryParams['per-page'] && {
            'per-page': this._queryParams['per-page'] || _filterForm.get('per-page').value,
        })), isNil);
        this._queryParams.__proto__ = this;
        this._filterFormInitialValue = omitBy(filterFormInitialValue, isNil);
        if (get(this._queryParams, 'group_by')) {
            this._queryParams['group_by'] = this._queryParams['group_by']
                ? RoutingHelper.convertParamsToArray(this._queryParams['group_by']).map((i) => i)
                : this._filterFormInitialValue['group_by'];
        }
    }
    toArray(args = []) {
        const multiselectParams = {};
        Object.keys(this._queryParams).forEach((key) => {
            if (args.includes(key)) {
                multiselectParams[key] = RoutingHelper.convertParamsToArray(this._queryParams[key]).map((i) => i);
            }
        });
        return Object.assign(this._queryParams, multiselectParams);
    }
    toNum(args = []) {
        const params = {};
        Object.keys(this._queryParams).forEach((key) => {
            if (args.includes(key)) {
                params[key] = +this._queryParams[key];
            }
        });
        return Object.assign(this._queryParams, params);
    }
    toNumArray(args = []) {
        const multiselectParams = {};
        Object.keys(this._queryParams).forEach((key) => {
            if (args.includes(key)) {
                multiselectParams[key] = RoutingHelper.convertParamsToArray(this._queryParams[key]).map((i) => args.includes(key) ? +i : i);
            }
        });
        return Object.assign(this._queryParams, multiselectParams);
    }
    toDate(args = []) {
        const params = {};
        Object.keys(this._queryParams).forEach((key) => {
            if (args.includes(key)) {
                params[key] = DateTime.fromISO(this._queryParams[key]);
            }
        });
        return Object.assign(this._queryParams, params);
    }
    toBoolean(args = []) {
        const params = {};
        Object.keys(this._queryParams).forEach((key) => {
            if (args.includes(key)) {
                params[key] = typeof this._queryParams[key] === 'string' ? !!+this._queryParams[key] : null;
            }
        });
        return Object.assign(this._queryParams, params);
    }
    period() {
        const period = Object.assign(Object.assign(Object.assign({}, (this._queryParams.period_start && {
            period_start: this._queryParams.period_start
                ? DateTime.fromISO(this._queryParams.period_start)
                : this._filterFormInitialValue['period_start'],
        })), (this._queryParams.period_finish && {
            period_finish: this._queryParams.period_finish
                ? DateTime.fromISO(this._queryParams.period_finish)
                : this._filterFormInitialValue['period_finish'],
        })), (this._queryParams.period_type && {
            period_type: this._queryParams.period_type || PickerDefaultPeriod.key,
        }));
        return Object.assign(this._queryParams, period);
    }
    getParams() {
        return omitBy(Object.assign(Object.assign({}, this._filterFormInitialValue), this._queryParams), isNil);
    }
}
