import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { countryCodeList } from '@ho/tools/constants';
let CountryFlagService = class CountryFlagService {
    constructor() { }
    getCountryCode(id) {
        return countryCodeList.find((item) => +item.country_code === +id)
            ? countryCodeList.find((item) => +item.country_code === +id).alpha_code.toLowerCase()
            : '';
    }
    getCountryName(id) {
        return countryCodeList.find((item) => +item.country_code === +id)
            ? countryCodeList.find((item) => +item.country_code === +id).name
            : '';
    }
};
CountryFlagService.ctorParameters = () => [];
CountryFlagService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [])
], CountryFlagService);
export { CountryFlagService };
